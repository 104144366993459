/* src/index.css */

/* Import the Typekit fonts at the very top */
@import url('https://use.typekit.net/ofz2nnp.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Set the Nobel font as the base font for html and body */
@layer base {
  html, body {
    font-family: 'Nobel', sans-serif; /* Ensure that Nobel is the primary font */
  }
}

/* Custom Neon Text Effect */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin 20s linear infinite;
}
.background-video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Keeps the video in the background */
  overflow: hidden;
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.1); /* Transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  border-radius: 15px; /* Rounded corners */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Light border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  position: relative; /* Required for the 16:9 iframe positioning */
}

iframe {
  border-radius: 15px; /* Optional: Ensuring iframe aligns with glassmorphism style */
}

.skeleton-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none; /* Allow user to interact with video beneath */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Hero Section */
.hero {
  position: relative;
  z-index: 1; /* Ensures it stays on top if needed */
  height: 100vh; /* Full screen height */
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #6dd5ed, #2193b0); /* Optional background */
}

/* Projects Section */
.projects {
  position: relative;
  z-index: 0; /* Lower z-index so it appears below the hero */
  padding: 50px 0;
  background-color: #f7f9fc; /* Or any background color */
}

/* General Settings */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Avoid horizontal scrollbars */
}

/* To prevent sections from overlapping */
.projects {
  margin-top: -50px; /* Adjust margin to avoid overlap */
  padding-top: 100px; /* Ensure adequate padding */
}

/* In your CSS file or style tag */
.hero-section {
  --mouse-x: 50%;
  --mouse-y: 50%;
  background: radial-gradient(circle at var(--mouse-x) var(--mouse-y), #2563eb, #7c3aed, #9333ea);
  background-size: cover;
  background-repeat: no-repeat;
  transition: background 0.1s ease-out;
}
